import React, { useState, useEffect } from "react";
import api from "../api";

const AccountsDashboard = () => {
  const [accounts, setAccounts] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [newAccount, setNewAccount] = useState({
    name: "",
    currency: "PLN",
    active: true
  });
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    fetchAccounts();
    fetchCurrencies();
  }, []);

  const fetchAccounts = async () => {
    try {
      const response = await api.get("/accounts");
      setAccounts(response.data);
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
  };

  const fetchCurrencies = async () => {
    try {
      const res = await api.get("/currencies");
      setCurrencies(res.data);
    } catch (error) {
      console.error("Error fetching currencies:", error);
    }
  };

  const handleEdit = (id) => {
    setEditingId(id);
  };

  const handleSave = async (id) => {
    const accountToUpdate = accounts.find((a) => a.id === id);
    try {
      const response = await api.put(`/accounts/${id}`, accountToUpdate);
      setAccounts(accounts.map((account) => (account.id === id ? response.data : account)));
      setEditingId(null);
    } catch (error) {
      console.error("Error updating account:", error);
      alert("Error updating account. Please try again.");
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this account?")) {
      try {
        await api.delete(`/accounts/${id}`);
        setAccounts(accounts.filter((account) => account.id !== id));
      } catch (error) {
        console.error("Error deleting account:", error);
        alert("Error deleting account. Please try again.");
      }
    }
  };

  const handleChange = (id, field, value) => {
    setAccounts(accounts.map((account) => (account.id === id ? { ...account, [field]: value } : account)));
  };

  const handleNewAccountChange = (field, value) => {
    setNewAccount({ ...newAccount, [field]: value });
  };

  const handleAddAccount = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post("/accounts", newAccount);
      setAccounts([...accounts, response.data].sort((a, b) => a.name.localeCompare(b.name)));
      setNewAccount({ name: "", currency: "PLN", active: true });
    } catch (error) {
      console.error("Error adding new account:", error);
      alert("Error adding new account. Please try again.");
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Accounts Dashboard</h2>
      <form onSubmit={handleAddAccount} className="mb-8 bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
          <input
            type="text"
            value={newAccount.name}
            onChange={(e) => handleNewAccountChange("name", e.target.value)}
            placeholder="New Account Name"
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <select
            value={newAccount.currency}
            onChange={(e) => handleNewAccountChange("currency", e.target.value)}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            {currencies.map((currency) => (
              <option key={currency} value={currency}>
                {currency}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={newAccount.active}
              onChange={(e) => handleNewAccountChange("active", e.target.checked)}
              className="mr-2"
            />
            Active
          </label>
        </div>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Add New Account
        </button>
      </form>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-4 py-2">ID</th>
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2">Currency</th>
              <th className="px-4 py-2">Active</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {accounts.map((account) => (
              <tr key={account.id} className="border-b">
                <td className="px-4 py-2">{account.id}</td>
                <td className="px-4 py-2">
                  {editingId === account.id ? (
                    <input
                      type="text"
                      value={account.name}
                      onChange={(e) => handleChange(account.id, "name", e.target.value)}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  ) : (
                    account.name
                  )}
                </td>
                <td className="px-4 py-2">
                  {editingId === account.id ? (
                    <select
                      value={account.currency}
                      onChange={(e) => handleChange(account.id, "currency", e.target.value)}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    >
                      {currencies.map((currency) => (
                        <option key={currency} value={currency}>
                          {currency}
                        </option>
                      ))}
                    </select>
                  ) : (
                    account.currency
                  )}
                </td>
                <td className="px-4 py-2">
                  {editingId === account.id ? (
                    <input
                      type="checkbox"
                      checked={account.active}
                      onChange={(e) => handleChange(account.id, "active", e.target.checked)}
                    />
                  ) : account.active ? (
                    "Yes"
                  ) : (
                    "No"
                  )}
                </td>
                <td className="px-4 py-2">
                  {editingId === account.id ? (
                    <button
                      onClick={() => handleSave(account.id)}
                      className="bg-green-500 hover:bg-green-700 text-white p-1 rounded mr-1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  ) : (
                    <>
                      <button
                        onClick={() => handleEdit(account.id)}
                        className="bg-blue-500 hover:bg-blue-700 text-white p-1 rounded mr-1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                        </svg>
                      </button>
                      <button
                        onClick={() => handleDelete(account.id)}
                        className="bg-red-500 hover:bg-red-700 text-white p-1 rounded"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AccountsDashboard;
