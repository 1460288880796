import React, { useState, useEffect, useRef } from "react";
import { format, addDays, parseISO } from "date-fns";
import { CURRENCIES } from "../constants/currencies";
import api, { fetchAccounts, fetchCategories } from "../api";
import { Autocomplete, TextField } from "@mui/material";

const AddExpense = () => {
  const [step, setStep] = useState(1);
  const [successMessage, setSuccessMessage] = useState("");
  const [formData, setFormData] = useState({
    date: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
    amount: "",
    currency: "PLN",
    amount_in_pln: "",
    account_id: "",
    category_id: "",
    subcategory_id: "",
    vendor: "",
    note: "",
    transaction_type: "expense"
  });

  const [accounts, setAccounts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  const amountInputRef = useRef(null);

  const [exchangeRate, setExchangeRate] = useState(null);

  const [vendors, setVendors] = useState([]);

  const [lastTransactionDate, setLastTransactionDate] = useState(null);

  const [recentTransactions, setRecentTransactions] = useState([]);

  const [allVendors, setAllVendors] = useState([]);

  useEffect(() => {
    fetchAccountsData();
    fetchCategoriesData();
    fetchLastTransactionDate();
    fetchRecentTransactions();
    fetchAllVendors();
  }, []);

  useEffect(() => {
    if (step === 3 && amountInputRef.current) {
      amountInputRef.current.focus();
    }
  }, [step]);

  useEffect(() => {
    if (formData.date && formData.currency) {
      fetchExchangeRate(formData.date, formData.currency);
    }
  }, [formData.date, formData.currency]);

  useEffect(() => {
    if (exchangeRate !== null) {
      setFormData((prev) => ({
        ...prev,
        amount_in_pln: (parseFloat(prev.amount) * exchangeRate).toFixed(2)
      }));
    }
  }, [exchangeRate]);

  useEffect(() => {
    if (step === 3 && formData.subcategory_id) {
      fetchVendors(formData.subcategory_id);
    }
  }, [step, formData.subcategory_id]);

  const fetchAccountsData = async () => {
    try {
      const activeAccounts = await fetchAccounts();
      setAccounts(activeAccounts.filter((account) => account.active)); // Only set active accounts
      const revolutAccount = activeAccounts.find((account) => account.name === "Revolut" && account.active);
      if (revolutAccount) {
        setFormData((prev) => ({ ...prev, account_id: revolutAccount.id }));
      }
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
  };

  const fetchCategoriesData = async () => {
    try {
      const allCategories = await fetchCategories();
      setCategories(allCategories.filter((category) => category.active));
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchSubcategories = async (categoryId) => {
    try {
      const response = await api.get(`/subcategories?category_id=${categoryId}`);
      setSubcategories(response.data.filter((subcategory) => subcategory.active));
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  const fetchExchangeRate = async (date, currency) => {
    if (currency === "PLN") {
      setExchangeRate(1);
      return;
    }

    const apiDate = format(new Date(date), "yyyy-MM-dd");
    const url = `https://api.frankfurter.app/${apiDate}?base=${currency}&symbols=PLN`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      setExchangeRate(data.rates.PLN);
    } catch (error) {
      console.error("Error fetching exchange rate:", error);
      setExchangeRate(null);
    }
  };

  const fetchVendors = async (subcategoryId) => {
    try {
      const response = await api.get(`/vendors?subcategory_id=${subcategoryId}`);
      setVendors(response.data);
    } catch (error) {
      console.error("Error fetching vendors:", error);
    }
  };

  const fetchLastTransactionDate = async () => {
    try {
      const response = await api.get("/expenses?limit=1&sort=id,desc");
      if (response.data.expenses.length > 0) {
        const lastDate = parseISO(response.data.expenses[0].date);
        setLastTransactionDate(lastDate);
      }
    } catch (error) {
      console.error("Error fetching last transaction date:", error);
    }
  };

  const fetchRecentTransactions = async () => {
    try {
      const response = await api.get("/expenses?limit=10&sort=id,desc");
      setRecentTransactions(response.data.expenses);
    } catch (error) {
      console.error("Error fetching recent transactions:", error);
    }
  };

  const fetchAllVendors = async () => {
    try {
      const response = await api.get("/vendors/all");
      setAllVendors(response.data);
    } catch (error) {
      console.error("Error fetching all vendors:", error);
    }
  };

  const handleTypeChange = (type) => {
    setFormData((prev) => ({ ...prev, transaction_type: type }));
  };

  const handleCategorySelect = (categoryId) => {
    setFormData((prev) => ({ ...prev, category_id: categoryId }));
    fetchSubcategories(categoryId);
    setStep(2);
  };

  const handleSubcategorySelect = (subcategoryId) => {
    setFormData((prev) => ({ ...prev, subcategory_id: subcategoryId }));
    setStep(3);
    setDefaultAccount();
    // Clear vendors when moving to step 3
    setVendors([]);
  };

  const handleSkipSubcategory = () => {
    setFormData((prev) => ({ ...prev, subcategory_id: null }));
    setStep(3);
    setDefaultAccount();
    // Clear vendors when skipping subcategory
    setVendors([]);
  };

  const setDefaultAccount = () => {
    const revolutAccount = accounts.find((account) => account.name === "Revolut");
    if (revolutAccount) {
      setFormData((prev) => ({ ...prev, account_id: revolutAccount.id }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updatedData = { ...prev, [name]: value };

      if (name === "amount") {
        // Convert comma to period for decimal separator
        const normalizedAmount = value.replace(",", ".");
        if (exchangeRate !== null) {
          updatedData.amount_in_pln = (parseFloat(normalizedAmount) * exchangeRate).toFixed(2);
        }
      }

      return updatedData;
    });
  };

  const handleVendorClick = (vendorName) => {
    setFormData((prev) => ({ ...prev, vendor: vendorName }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dataToSubmit = {
        ...formData,
        date: new Date(formData.date).toISOString(),
        amount_in_pln: parseFloat(formData.amount_in_pln)
      };
      await api.post("/expenses", dataToSubmit);
      setSuccessMessage("Transaction added successfully!");

      // Update lastTransactionDate with the newly added transaction date
      const newTransactionDate = parseISO(dataToSubmit.date);
      setLastTransactionDate(newTransactionDate);

      // Reset form and go back to step 1
      setFormData({
        date: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
        amount: "",
        currency: "PLN",
        amount_in_pln: "",
        account_id: "",
        category_id: "",
        subcategory_id: "",
        vendor: "",
        note: "",
        transaction_type: "expense"
      });
      setStep(1);
      setVendors([]);

      // Fetch recent transactions to update the list
      fetchRecentTransactions();

      // Clear success message after 3 seconds
      setTimeout(() => setSuccessMessage(""), 3000);
    } catch (error) {
      console.error("Error adding transaction:", error.response?.data || error.message);
      alert(`Error adding transaction: ${error.response?.data?.message || error.message}`);
    }
  };

  const handleDateLinkClick = (date) => {
    setFormData((prev) => ({ ...prev, date: format(date, "yyyy-MM-dd'T'HH:mm") }));
  };

  const renderStep1 = () => (
    <div>
      <div className="flex justify-around border-b border-gray-300 mb-4">
        <button
          onClick={() => handleTypeChange("expense")}
          className={`flex-1 pt-0 pb-2 text-center ${
            formData.transaction_type === "expense" ? "text-black border-b-2 border-blue-500" : "text-gray-500"
          }`}
        >
          Expense
        </button>
        <button
          onClick={() => handleTypeChange("income")}
          className={`flex-1 pt-0 pb-2 text-center ${
            formData.transaction_type === "income" ? "text-black border-b-2 border-blue-500" : "text-gray-500"
          }`}
        >
          Income
        </button>
      </div>
      <h2 className="text-2xl font-bold mb-2 mt-4">Category</h2>
      <div>
        {categories
          .filter((category) => {
            if (formData.transaction_type === "expense") {
              return category.category_type === "expenses" || category.category_type === "expense";
            } else {
              return category.category_type === "income";
            }
          })
          .map((category) => (
            <button
              key={category.id}
              onClick={() => handleCategorySelect(category.id)}
              className="bg-gray-200 text-gray-700 hover:bg-gray-300 px-6 py-3 rounded-md my-1 ml-0 mr-2 text-lg"
            >
              {category.name}
            </button>
          ))}
      </div>

      <div className="mt-8">
        <h3 className="text-xl font-semibold mb-4">Recent Transactions</h3>
        <div className="overflow-x-auto">
          <table className="min-w-full">
            <tbody>
              {recentTransactions.map((transaction) => (
                <tr key={transaction.id} className="border-t">
                  <td className="pl-0 pr-2 py-2 text-gray-600 whitespace-nowrap">
                    {format(parseISO(transaction.date), "MMM d")}
                  </td>
                  <td className="px-2 py-2 whitespace-nowrap">
                    <span className="font-medium">
                      {parseFloat(transaction.amount) % 1 === 0
                        ? parseInt(transaction.amount).toLocaleString()
                        : parseFloat(transaction.amount).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}{" "}
                    </span>
                    {transaction.currency}
                  </td>
                  <td className="px-2 py-2">
                    {transaction.category_name}
                    {transaction.subcategory_name && ` › ${transaction.subcategory_name}`}
                  </td>
                  <td className="px-4 py-2 text-gray-600">{transaction.vendor || "-"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  const renderStep2 = () => {
    const selectedCategory = categories.find((c) => c.id === formData.category_id);
    return (
      <div>
        <div className="flex justify-between items-center mb-4">
          <button className="text-blue-500 p-1" onClick={() => setStep(1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
          </button>
          <h2 className="text-xl font-bold">Subcategory</h2>
        </div>
        {selectedCategory && <div className="mb-4 text-lg font-semibold">Category: {selectedCategory.name}</div>}
        <div>
          {subcategories.map((subcategory) => (
            <button
              key={subcategory.id}
              onClick={() => handleSubcategorySelect(subcategory.id)}
              className="bg-gray-200 text-gray-700 hover:bg-gray-300 px-6 py-3 rounded-md my-1 ml-0 mr-2 text-lg"
            >
              {subcategory.name}
            </button>
          ))}
        </div>
        <button
          onClick={handleSkipSubcategory}
          className="bg-gray-300 text-gray-700 hover:bg-gray-400 px-4 py-2 rounded-md m-1 mt-4"
        >
          Skip Subcategory
        </button>
      </div>
    );
  };

  const renderDateLinks = () => {
    if (!lastTransactionDate) {
      return null;
    }

    const currentDate = parseISO(formData.date);

    // Show date links if the current form date is different from the last transaction date
    if (format(currentDate, "yyyy-MM-dd") === format(lastTransactionDate, "yyyy-MM-dd")) {
      return null;
    }

    const nextDay = addDays(lastTransactionDate, 1);

    return (
      <div className="mt-2 text-sm">
        <button onClick={() => handleDateLinkClick(lastTransactionDate)} className="text-blue-500 hover:underline mr-4">
          {format(lastTransactionDate, "yyyy-MM-dd")}
        </button>
        <button onClick={() => handleDateLinkClick(nextDay)} className="text-blue-500 hover:underline">
          {format(nextDay, "yyyy-MM-dd")}
        </button>
      </div>
    );
  };

  const renderStep3 = () => {
    const selectedCategory = categories.find((c) => c.id === formData.category_id);
    const selectedSubcategory = subcategories.find((s) => s.id === formData.subcategory_id);

    return (
      <div className="p-4">
        <div className="flex justify-between items-center mb-4">
          <button className="text-blue-500 p-1" onClick={() => setStep(2)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
          </button>
          <h2 className="text-xl font-bold">
            {selectedCategory?.name} {selectedSubcategory && `› ${selectedSubcategory.name}`}
          </h2>
        </div>

        <div className="mb-4">
          <input
            ref={amountInputRef}
            name="amount"
            value={formData.amount}
            onChange={handleChange}
            placeholder="Amount"
            className="text-4xl font-bold w-full text-center placeholder-gray-200"
            required
            inputMode="decimal"
          />
          <div className="flex gap-2 mt-2">
            <input
              type="date"
              name="date"
              value={formData.date.split("T")[0]}
              onChange={handleChange}
              className="border rounded p-2 flex-1"
              required
            />
            <select
              name="currency"
              value={formData.currency}
              onChange={handleChange}
              className="border rounded p-2 w-24"
            >
              {CURRENCIES.map((currency) => (
                <option key={currency.value} value={currency.value}>
                  {currency.label}
                </option>
              ))}
            </select>
          </div>
          {formData.currency !== "PLN" && (
            <div className="mt-2 text-sm text-gray-600">
              Amount in PLN: {formData.amount_in_pln || "Calculating..."}
            </div>
          )}
          {renderDateLinks()}
        </div>

        <div className="mb-4 flex flex-wrap">
          {accounts.map((account) => (
            <button
              key={account.id}
              onClick={() => handleChange({ target: { name: "account_id", value: account.id } })}
              className={`m-1 p-2 rounded ${
                formData.account_id === account.id ? "bg-blue-500 text-white" : "bg-gray-200"
              }`}
            >
              {account.name}
            </button>
          ))}
        </div>

        <div className="mb-4">
          <Autocomplete
            freeSolo
            options={allVendors.map((vendor) => vendor.name)}
            value={formData.vendor}
            onChange={(_, newValue) => {
              setFormData((prev) => ({ ...prev, vendor: newValue }));
            }}
            onInputChange={(_, newValue) => {
              setFormData((prev) => ({ ...prev, vendor: newValue }));
            }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Vendor" className="border rounded p-2 w-full" variant="standard" />
            )}
          />
          {vendors.length > 0 && (
            <div className="mt-2">
              <p className="text-sm text-gray-600 mb-1">Previous vendors for this subcategory:</p>
              <div className="flex flex-wrap">
                {vendors.map((vendor, index) => (
                  <button
                    key={index}
                    onClick={() => handleVendorClick(vendor.name)}
                    className="bg-gray-200 text-gray-700 hover:bg-gray-300 px-2 py-1 rounded-md m-1 text-sm"
                  >
                    {vendor.name} ({vendor.count})
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="mb-4">
          <textarea
            name="note"
            value={formData.note}
            onChange={handleChange}
            placeholder="Note"
            className="border rounded p-2 w-full h-11"
          />
        </div>

        <button onClick={handleSubmit} className="bg-blue-500 text-white p-4 rounded w-full font-medium text-xl">
          Save
        </button>
      </div>
    );
  };

  return (
    <div className="relative min-h-screen">
      {step === 1 && renderStep1()}
      {step === 2 && renderStep2()}
      {step === 3 && renderStep3()}

      {/* Fixed notification at the bottom */}
      {successMessage && (
        <div className="fixed bottom-0 left-0 right-0 bg-green-500 text-white px-4 pt-4 pb-8 text-center transition-all duration-300 ease-in-out">
          {successMessage}
        </div>
      )}
    </div>
  );
};

export default AddExpense;
