import React from "react";
import { BrowserRouter as Router, Route, Link, Routes, Navigate, useLocation } from "react-router-dom";
import AdminDashboard from "./components/AdminDashboard";
import CategoriesDashboard from "./components/CategoriesDashboard";
import SubcategoriesDashboard from "./components/SubcategoriesDashboard";
import AccountsDashboard from "./components/AccountsDashboard";
import AddExpense from "./components/AddExpense";
import Login from "./components/Login";

const Navigation = () => {
  const location = useLocation();

  // Hide navigation on the Add Expense page
  if (location.pathname === "/") {
    return null;
  }

  return (
    <nav className="bg-gray-800 p-4">
      <div className="container mx-auto">
        <ul className="flex space-x-4">
          <li>
            <Link to="/" className="text-white hover:text-gray-300 transition duration-150 ease-in-out">
              Add Expense
            </Link>
          </li>
          <li>
            <Link to="/admin" className="text-white hover:text-gray-300 transition duration-150 ease-in-out">
              Expenses
            </Link>
          </li>
          <li>
            <Link to="/admin/categories" className="text-white hover:text-gray-300 transition duration-150 ease-in-out">
              Categories
            </Link>
          </li>
          <li>
            <Link
              to="/admin/subcategories"
              className="text-white hover:text-gray-300 transition duration-150 ease-in-out"
            >
              Subcategories
            </Link>
          </li>
          <li>
            <Link to="/admin/accounts" className="text-white hover:text-gray-300 transition duration-150 ease-in-out">
              Accounts
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
  return isAuthenticated ? children : <Navigate to="/login" />;
};

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-gray-100">
        <Navigation />
        <div className="container mx-auto p-4">
          <Routes>
            <Route exact path="/" element={<AddExpense />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/admin"
              element={
                <ProtectedRoute>
                  <AdminDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/categories"
              element={
                <ProtectedRoute>
                  <CategoriesDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/subcategories"
              element={
                <ProtectedRoute>
                  <SubcategoriesDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/accounts"
              element={
                <ProtectedRoute>
                  <AccountsDashboard />
                </ProtectedRoute>
              }
            />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
