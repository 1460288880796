import React, { useState, useEffect } from "react";
import api from "../api";
import { format, parseISO } from "date-fns";

const AdminDashboard = () => {
  const [expenses, setExpenses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    category: "",
    account: "",
    vendor: "",
    transaction_type: ""
  });
  const [categories, setCategories] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [subcategories, setSubcategories] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    fetchExpenses();
    fetchCategories();
    fetchAccounts();
    fetchCurrencies();
  }, [currentPage, filters]);

  const fetchExpenses = async () => {
    try {
      const response = await api.get("/expenses", {
        params: {
          page: currentPage,
          limit: 100,
          ...filters
        }
      });
      setExpenses(response.data.expenses);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching expenses:", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await api.get("/categories");
      console.log("Fetched categories:", response.data); // Add this line for debugging
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchAccounts = async () => {
    try {
      const response = await api.get("/accounts");
      setAccounts(response.data);
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
  };

  const fetchCurrencies = async () => {
    try {
      const response = await api.get("/currencies");
      setCurrencies(response.data);
    } catch (error) {
      console.error("Error fetching currencies:", error);
    }
  };

  const fetchSubcategories = async (categoryId) => {
    try {
      const response = await api.get(`/subcategories?category_id=${categoryId}`);
      setSubcategories(response.data.filter((subcategory) => subcategory.active));
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleEdit = (id) => {
    setEditingId(id);
    const expense = expenses.find((e) => e.id === id);
    if (expense) {
      fetchSubcategories(expense.category_id);
    }
  };

  const handleSave = async (id) => {
    const expenseToUpdate = expenses.find((e) => e.id === id);
    try {
      await api.put(`/expenses/${id}`, expenseToUpdate);
      setEditingId(null);
      fetchExpenses();
    } catch (error) {
      console.error("Error updating expense:", error);
      alert("Error updating expense. Please try again.");
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this expense?")) {
      try {
        await api.delete(`/expenses/${id}`);
        fetchExpenses();
      } catch (error) {
        console.error("Error deleting expense:", error);
        alert("Error deleting expense. Please try again.");
      }
    }
  };

  const handleChange = (id, field, value) => {
    setExpenses(
      expenses.map((expense) => {
        if (expense.id === id) {
          const updatedExpense = { ...expense, [field]: value };
          if (field === "category_id") {
            updatedExpense.subcategory_id = null;
            fetchSubcategories(value);
          }
          return updatedExpense;
        }
        return expense;
      })
    );
  };

  const getActiveCategories = (transactionType) => {
    return categories.filter(
      (category) =>
        category.active &&
        (category.category_type === transactionType ||
          (transactionType === "expense" && category.category_type === "expenses") ||
          (transactionType === "expenses" && category.category_type === "expense"))
    );
  };

  const formatAmount = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(parseFloat(amount || 0));
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Admin Dashboard</h1>

      {/* Filters */}
      <div className="mb-4 flex flex-wrap gap-4">
        <input
          type="date"
          name="startDate"
          value={filters.startDate}
          onChange={handleFilterChange}
          className="border rounded p-2"
        />
        <input
          type="date"
          name="endDate"
          value={filters.endDate}
          onChange={handleFilterChange}
          className="border rounded p-2"
        />
        <select name="category" value={filters.category} onChange={handleFilterChange} className="border rounded p-2">
          <option value="">All Categories</option>
          {categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>
        <select name="account" value={filters.account} onChange={handleFilterChange} className="border rounded p-2">
          <option value="">All Accounts</option>
          {accounts.map((account) => (
            <option key={account.id} value={account.id}>
              {account.name}
            </option>
          ))}
        </select>
        <input
          type="text"
          name="vendor"
          value={filters.vendor}
          onChange={handleFilterChange}
          placeholder="Vendor"
          className="border rounded p-2"
        />
        <select
          name="transaction_type"
          value={filters.transaction_type}
          onChange={handleFilterChange}
          className="border rounded p-2"
        >
          <option value="">All Types</option>
          <option value="expense">Expense</option>
          <option value="income">Income</option>
        </select>
      </div>

      {/* Expenses Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-4 py-2">Date</th>
              <th className="px-4 py-2">Amount</th>
              <th className="px-4 py-2">Currency</th>
              <th className="px-4 py-2">Amount in PLN</th>
              <th className="px-4 py-2">Category</th>
              <th className="px-4 py-2">Subcategory</th>
              <th className="px-4 py-2">Account</th>
              <th className="px-4 py-2">Vendor</th>
              <th className="px-4 py-2">Note</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {expenses.map((expense) => (
              <tr key={expense.id} className="border-b">
                <td className="px-4 py-2">
                  {editingId === expense.id ? (
                    <input
                      type="datetime-local"
                      value={format(parseISO(expense.date), "yyyy-MM-dd'T'HH:mm")}
                      onChange={(e) => handleChange(expense.id, "date", e.target.value)}
                      className="w-full p-1 border rounded"
                    />
                  ) : (
                    format(parseISO(expense.date), "yyyy-MM-dd HH:mm")
                  )}
                </td>
                <td className="px-4 py-2">
                  {editingId === expense.id ? (
                    <input
                      type="number"
                      value={expense.amount}
                      onChange={(e) => handleChange(expense.id, "amount", e.target.value)}
                      className="w-full p-1 border rounded"
                    />
                  ) : (
                    formatAmount(expense.amount)
                  )}
                </td>
                <td className="px-4 py-2">
                  {editingId === expense.id ? (
                    <select
                      value={expense.currency}
                      onChange={(e) => handleChange(expense.id, "currency", e.target.value)}
                      className="w-full p-1 border rounded"
                    >
                      {currencies.map((currency) => (
                        <option key={currency} value={currency}>
                          {currency}
                        </option>
                      ))}
                    </select>
                  ) : (
                    expense.currency
                  )}
                </td>
                <td className="px-4 py-2">
                  {editingId === expense.id ? (
                    <input
                      type="number"
                      value={expense.amount_in_pln}
                      onChange={(e) => handleChange(expense.id, "amount_in_pln", e.target.value)}
                      className="w-full p-1 border rounded"
                    />
                  ) : (
                    formatAmount(expense.amount_in_pln)
                  )}
                </td>
                <td className="px-4 py-2">
                  {editingId === expense.id ? (
                    <select
                      value={expense.category_id}
                      onChange={(e) => handleChange(expense.id, "category_id", e.target.value)}
                      className="w-full p-1 border rounded"
                    >
                      <option value="">Select Category</option>
                      {getActiveCategories(expense.transaction_type).map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    expense.category_name
                  )}
                </td>
                <td className="px-4 py-2">
                  {editingId === expense.id ? (
                    <select
                      value={expense.subcategory_id || ""}
                      onChange={(e) => handleChange(expense.id, "subcategory_id", e.target.value)}
                      className="w-full p-1 border rounded"
                    >
                      <option value="">Select Subcategory</option>
                      {subcategories.map((subcategory) => (
                        <option key={subcategory.id} value={subcategory.id}>
                          {subcategory.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    expense.subcategory_name || "-"
                  )}
                </td>
                <td className="px-4 py-2">
                  {editingId === expense.id ? (
                    <select
                      value={expense.account_id}
                      onChange={(e) => handleChange(expense.id, "account_id", e.target.value)}
                      className="w-full p-1 border rounded"
                    >
                      {accounts.map((account) => (
                        <option key={account.id} value={account.id}>
                          {account.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    expense.account_name
                  )}
                </td>
                <td className="px-4 py-2">
                  {editingId === expense.id ? (
                    <input
                      type="text"
                      value={expense.vendor}
                      onChange={(e) => handleChange(expense.id, "vendor", e.target.value)}
                      className="w-full p-1 border rounded"
                    />
                  ) : (
                    expense.vendor || "-"
                  )}
                </td>
                <td className="px-4 py-2">
                  {editingId === expense.id ? (
                    <input
                      type="text"
                      value={expense.note}
                      onChange={(e) => handleChange(expense.id, "note", e.target.value)}
                      className="w-full p-1 border rounded"
                    />
                  ) : (
                    expense.note || "-"
                  )}
                </td>
                <td className="px-4 py-2">
                  {editingId === expense.id ? (
                    <button
                      onClick={() => handleSave(expense.id)}
                      className="bg-green-500 hover:bg-green-700 text-white p-1 rounded mr-1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  ) : (
                    <>
                      <button
                        onClick={() => handleEdit(expense.id)}
                        className="bg-blue-500 hover:bg-blue-700 text-white p-1 rounded mr-1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                        </svg>
                      </button>
                      <button
                        onClick={() => handleDelete(expense.id)}
                        className="bg-red-500 hover:bg-red-700 text-white p-1 rounded"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="mt-4 flex flex-wrap justify-center">
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={`mx-1 my-1 px-3 py-1 rounded ${currentPage === page ? "bg-blue-500 text-white" : "bg-gray-200"}`}
          >
            {page}
          </button>
        ))}
      </div>
    </div>
  );
};

export default AdminDashboard;
