import React, { useState, useEffect } from "react";
import api from "../api";

const MergeSubcategories = ({ onMergeComplete }) => {
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [targetSubcategory, setTargetSubcategory] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (isExpanded) {
      fetchCategories();
      fetchAllSubcategories();
    }
  }, [isExpanded]);

  const fetchCategories = async () => {
    try {
      const response = await api.get("/categories");
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchAllSubcategories = async () => {
    try {
      const response = await api.get("/subcategories");
      setSubcategories(response.data);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  const handleSubcategorySelect = (subcategoryId) => {
    setSelectedSubcategories((prev) =>
      prev.includes(subcategoryId) ? prev.filter((id) => id !== subcategoryId) : [...prev, subcategoryId]
    );
  };

  const handleMerge = async () => {
    if (selectedSubcategories.length < 2 || !targetSubcategory) {
      alert("Please select at least two subcategories and a target subcategory.");
      return;
    }

    try {
      await api.post("/merge-subcategories", {
        subcategories: selectedSubcategories,
        targetSubcategory
      });
      alert("Subcategories merged successfully!");
      onMergeComplete();
      setSelectedSubcategories([]);
      setTargetSubcategory("");
    } catch (error) {
      console.error("Error merging subcategories:", error);
      alert("Error merging subcategories. Please try again.");
    }
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="mt-4 border rounded p-4">
      <button onClick={toggleExpand} className="w-full text-left font-semibold flex justify-between items-center">
        <span>Merge Subcategories</span>
        <span>{isExpanded ? "▲" : "▼"}</span>
      </button>
      {isExpanded && (
        <div className="mt-4">
          <div className="mb-4">
            {categories.map((category) => (
              <div key={category.id} className="mb-2">
                <h4 className="font-semibold">{category.name}</h4>
                {subcategories
                  .filter((sub) => sub.category_id === category.id)
                  .map((subcategory) => (
                    <label key={subcategory.id} className="block">
                      <input
                        type="checkbox"
                        checked={selectedSubcategories.includes(subcategory.id)}
                        onChange={() => handleSubcategorySelect(subcategory.id)}
                        className="mr-2"
                      />
                      {subcategory.name}
                    </label>
                  ))}
              </div>
            ))}
          </div>
          <div className="mb-4">
            <label className="block mb-2">Select target subcategory:</label>
            <select
              value={targetSubcategory}
              onChange={(e) => setTargetSubcategory(e.target.value)}
              className="w-full p-2 border rounded"
            >
              <option value="">Select a subcategory</option>
              {subcategories
                .filter((sub) => selectedSubcategories.includes(sub.id))
                .map((subcategory) => (
                  <option key={subcategory.id} value={subcategory.id}>
                    {subcategory.name} ({categories.find((c) => c.id === subcategory.category_id)?.name})
                  </option>
                ))}
            </select>
          </div>
          <button onClick={handleMerge} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
            Merge Selected Subcategories
          </button>
        </div>
      )}
    </div>
  );
};

export default MergeSubcategories;
