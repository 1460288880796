import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "https://cfdb.vlvt.sh";

// Create an axios instance with the base URL
const api = axios.create({
  baseURL: API_URL
});

export const fetchCategories = async () => {
  try {
    const response = await api.get("/categories");
    return response.data;
  } catch (error) {
    console.error("Error fetching categories:", error);
    return [];
  }
};

export const fetchSubcategories = async () => {
  try {
    const response = await api.get("/subcategories");
    return response.data;
  } catch (error) {
    console.error("Error fetching subcategories:", error);
    return [];
  }
};

export const fetchAccounts = async () => {
  try {
    const response = await api.get("/accounts");
    return response.data;
  } catch (error) {
    console.error("Error fetching accounts:", error);
    return [];
  }
};

// Export the api instance for use in other components
export default api;
