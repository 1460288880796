import React, { useState, useEffect } from "react";
import { format, parseISO } from "date-fns";
import api from "../api";
import MergeSubcategories from "./MergeSubcategories";

const SubcategoriesDashboard = () => {
  const [subcategories, setSubcategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [newSubcategory, setNewSubcategory] = useState({
    name: "",
    category_id: "",
    active: true
  });
  const [isAddSectionExpanded, setIsAddSectionExpanded] = useState(false);

  useEffect(() => {
    fetchSubcategories();
    fetchCategories();
  }, []);

  const fetchSubcategories = async () => {
    try {
      const response = await api.get("/subcategories");
      setSubcategories(response.data);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await api.get("/categories");
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleEdit = (id) => {
    setEditingId(id);
  };

  const handleSave = async (id) => {
    const subcategoryToUpdate = subcategories.find((s) => s.id === id);
    try {
      const response = await api.put(`/subcategories/${id}`, {
        name: subcategoryToUpdate.name,
        category_id: subcategoryToUpdate.category_id,
        active: subcategoryToUpdate.active
      });
      setEditingId(null);
      setSubcategories(subcategories.map((sub) => (sub.id === id ? response.data : sub)));
    } catch (error) {
      console.error("Error updating subcategory:", error);
      alert(`Error updating subcategory: ${error.response?.data?.message || error.message}`);
    }
  };

  const handleChange = (id, field, value) => {
    setSubcategories(
      subcategories.map((subcategory) => (subcategory.id === id ? { ...subcategory, [field]: value } : subcategory))
    );
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this subcategory?")) {
      try {
        await api.delete(`/subcategories/${id}`);
        setSubcategories(subcategories.filter((subcategory) => subcategory.id !== id));
      } catch (error) {
        console.error("Error deleting subcategory:", error);
        alert("Error deleting subcategory. Please try again.");
      }
    }
  };

  const handleNewSubcategoryChange = (field, value) => {
    setNewSubcategory({ ...newSubcategory, [field]: value });
  };

  const handleAddSubcategory = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post("/subcategories", newSubcategory);
      setSubcategories([...subcategories, response.data]);
      setNewSubcategory({ name: "", category_id: "", active: true });
      setIsAddSectionExpanded(false);
    } catch (error) {
      console.error("Error adding new subcategory:", error);
      alert(`Error adding new subcategory: ${error.response?.data?.message || error.message}`);
    }
  };

  const handleMergeComplete = () => {
    fetchSubcategories();
  };

  const toggleAddSection = () => {
    setIsAddSectionExpanded(!isAddSectionExpanded);
  };

  const formatAmount = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(parseFloat(amount || 0));
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Subcategories Dashboard</h2>

      <div className="mb-8 bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <button
          onClick={toggleAddSection}
          className="w-full text-left font-semibold flex justify-between items-center mb-4"
        >
          <span>Add New Subcategory</span>
          <span>{isAddSectionExpanded ? "▲" : "▼"}</span>
        </button>
        {isAddSectionExpanded && (
          <form onSubmit={handleAddSubcategory}>
            <div className="mb-4">
              <input
                type="text"
                value={newSubcategory.name}
                onChange={(e) => handleNewSubcategoryChange("name", e.target.value)}
                placeholder="New Subcategory Name"
                required
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <select
                value={newSubcategory.category_id}
                onChange={(e) => handleNewSubcategoryChange("category_id", e.target.value)}
                required
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="">Select Category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={newSubcategory.active}
                  onChange={(e) => handleNewSubcategoryChange("active", e.target.checked)}
                  className="mr-2"
                />
                Active
              </label>
            </div>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Add New Subcategory
            </button>
          </form>
        )}
      </div>

      <div className="mb-8">
        <MergeSubcategories onMergeComplete={handleMergeComplete} />
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-4 py-2">ID</th>
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2">Category</th>
              <th className="px-4 py-2">Active</th>
              <th className="px-4 py-2">Last Transaction</th>
              <th className="px-4 py-2">Transaction Count</th>
              <th className="px-4 py-2">Total Amount (PLN)</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {subcategories.map((subcategory) => (
              <tr key={subcategory.id} className="border-b">
                <td className="px-4 py-2">{subcategory.id}</td>
                <td className="px-4 py-2">
                  {editingId === subcategory.id ? (
                    <input
                      type="text"
                      value={subcategory.name}
                      onChange={(e) => handleChange(subcategory.id, "name", e.target.value)}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  ) : (
                    subcategory.name
                  )}
                </td>
                <td className="px-4 py-2">
                  {editingId === subcategory.id ? (
                    <select
                      value={subcategory.category_id}
                      onChange={(e) => handleChange(subcategory.id, "category_id", e.target.value)}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    >
                      {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    subcategory.category_name
                  )}
                </td>
                <td className="px-4 py-2">
                  {editingId === subcategory.id ? (
                    <input
                      type="checkbox"
                      checked={subcategory.active}
                      onChange={(e) => handleChange(subcategory.id, "active", e.target.checked)}
                    />
                  ) : subcategory.active ? (
                    "Yes"
                  ) : (
                    "No"
                  )}
                </td>
                <td className="px-4 py-2">
                  {subcategory.last_transaction_date
                    ? format(parseISO(subcategory.last_transaction_date), "yyyy-MM-dd")
                    : "N/A"}
                </td>
                <td className="px-4 py-2">{subcategory.transaction_count || 0}</td>
                <td className="px-4 py-2">{formatAmount(subcategory.total_amount_pln)}</td>
                <td className="px-4 py-2">
                  {editingId === subcategory.id ? (
                    <button
                      onClick={() => handleSave(subcategory.id)}
                      className="bg-green-500 hover:bg-green-700 text-white p-1 rounded mr-1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  ) : (
                    <>
                      <button
                        onClick={() => handleEdit(subcategory.id)}
                        className="bg-blue-500 hover:bg-blue-700 text-white p-1 rounded mr-1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                        </svg>
                      </button>
                      <button
                        onClick={() => handleDelete(subcategory.id)}
                        className="bg-red-500 hover:bg-red-700 text-white p-1 rounded"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SubcategoriesDashboard;
